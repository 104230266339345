const CardData14 = [
  {
    title: 'Cafe del Mar',
    description:
      'El Café del Mar es un bar de Ibiza, situado junto a la playa de San Antonio, famoso por la selección de música que reproduce al atardecer.Construido sobre una idea del zaragozano Ramón Guiral Broto, Carlos Andrea y José Les, y diseñado por el arquitecto Lluis Güell, se inauguró el 20 de junio de 1980. El local explotó comercialmente una idea sencilla disfrutar de los atardeceres de la bahía de Caló Des Moro mientras se escucha buena música y se toma una copa. Desde 1994 el Café Del Mar ha editado cada año álbumes chill out, lounge, ambient, chill house y balearic beats con su selección musical que refleja el ambiente ibicenco.',
    videourl:
      'https://youtube.com/playlist?list=PLjr4y4MklyLSWqYbEo_rtZgKW8n6De2ED',
    price: 80,
    coverImg: 'cafedelmar.jpg',
    category: 'Electro Chill',
    location: 'Ibiza Spain',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://cafedelmar.com/',
    youtube: 'https://www.youtube.com/c/cafedelmar',
    facebook: 'https://www.facebook.com/officialcafedelmar',
    instagram: 'https://www.instagram.com/cafedelmar/',
    twitter: 'https://twitter.com/CafedelMar_Ibz',
    spotify: 'https://open.spotify.com/artist/4L74W177Wc8s7WrQHbVaXt',
    deezer: 'https://www.deezer.com/mx/artist/5374891',
    apple: 'https://music.apple.com/mx/artist/caf%C3%A9-del-mar/264182083',
    soundcloud: 'https://soundcloud.com/cafedelmarmusic',
    tidal: 'https://listen.tidal.com/artist/3973704',
    wiki: 'https://en.wikipedia.org/wiki/Caf%C3%A9_del_Mar',
    ticketmaster:
      'https://www.ticketmaster.es/venue/cafe-del-mar-club-barcelona-barcelona-entradas/ckdemarbcn/114',
    tiktok: 'https://www.tiktok.com/@cafedelmarmalta',
    napster:
      'https://us.napster.com/artist/various-artists/album/cafe-del-mar-wnts',
  },
  {
    title: 'Zero 7',
    description:
      'Zero 7 are an English musical duo consisting of Henry Binns and Sam Hardaker. They began as studio engineers and in 1997 formed the group Zero 7. Their debut album, Simple Things, was released in 2001 in which their song Destiny stayed in the top 100 of the UK Single Charts. Subsequent albums include When It Falls, The Garden, and Yeah Ghost.',
    videourl:
      'https://youtube.com/playlist?list=PLeaSD3s7Y5OghUUEosuP9raF-Jsy08JLQ',
    price: 80,
    coverImg: 'Zero7.jpg',
    category: 'Chill',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.zero7.co.uk/',
    youtube: 'https://www.youtube.com/channel/UCbhHVA3KrpylwWjFodZKEuA',
    facebook: 'https://www.facebook.com/Zero7',
    instagram: 'https://www.instagram.com/zero7hq/',
    twitter: 'https://twitter.com/zero7official',
    spotify:
      'https://open.spotify.com/playlist/2rcYYAFhx5mQaW8H91TtYp?si=GtTi3AQEQqy18Jj8g3YDlQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/608',
    apple: 'https://music.apple.com/mx/artist/zero-7/2509752',
    soundcloud: 'https://soundcloud.com/zero7',
    tidal: 'https://listen.tidal.com/artist/12173',
    wiki: 'https://en.wikipedia.org/wiki/Zero_7',
    ticketmaster: 'https://www.ticketmaster.com/zero-7-tickets/artist/846730',
    tiktok: 'https://www.tiktok.com/search?q=zero%207%20music&t=1682823932095',
    napster: 'https://music.amazon.com.mx/artists/B000QJPE8S/zero-7',
  },
  {
    title: 'Tycho',
    description:
      'Scott Hansen (nacido el 7 de febrero de 1977), conocido profesionalmente como Tycho (/ˈtaɪkoʊ/ TY-koh), es un músico, productor discográfico, compositor y autor de canciones estadounidense radicado en San Francisco. También es conocido como ISO50 por su trabajo fotográfico y de diseño. Su música combina múltiples componentes estilísticos, incluyendo guitarra downtempo, síntesis analógica y elementos ambientales como sonidos de respiración, transmisiones meteorológicas y diálogos encontrados.',
    videourl:
      'https://youtube.com/playlist?list=PL7oQgtXrnU0BYskZRr5gOQLGNcxp2dCPL',
    price: 80,
    coverImg: 'Tycho.jpg',
    category: 'Chill',
    location: 'UK',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://tychomusic.com/',
    youtube: 'https://www.youtube.com/channel/UCWzGCSf-1fluXYvVOuh-aVA',
    facebook: 'https://www.facebook.com/tychomusic',
    instagram: 'https://www.instagram.com/tychomusic/',
    twitter: 'https://twitter.com/ISO50',
    spotify: 'https://open.spotify.com/artist/5oOhM2DFWab8XhSdQiITry',
    deezer: 'https://www.deezer.com/mx/artist/63750',
    apple: 'https://music.apple.com/mx/artist/tycho/119111355',
    soundcloud: 'https://soundcloud.com/tycho',
    tidal: 'https://listen.tidal.com/artist/3599935',
    wiki: 'https://en.wikipedia.org/wiki/Tycho_(musician)',
    ticketmaster: 'https://www.ticketmaster.com/tycho-tickets/artist/1624535',
    tiktok: 'https://www.tiktok.com/@tycho_music',
    napster: 'https://music.amazon.com.mx/artists/B000QKTQJ0/tycho',
  },
  {
    title: 'The Theivery Corporation',
    description:
      'Thievery Corporation es un dúo de DJ y productores musicales de la ciudad de Washington, Estados Unidos. Lo integran Rob Garza y Eric Hilton y ocasionalmente, músicos de sesión.Su música puede definirse dentro del estilo de música electrónica denominado downtempo, con influencias del dub, el acid jazz, la música de la India y de Brasil (esencialmente bossa nova), en una fusión combinada con una estética lounge.Thievery Corporation edita su producción en el sello (propio) Eighteenth Street Lounge, así como también algunos de sus sencillos o EP han sido editados por los sellos 4AD y !K7..',
    videourl:
      'https://youtube.com/playlist?list=PLZMps7ZJMfTOCn0pqD3OzeoS9MM2nQJcT',
    price: 80,
    coverImg: 'thethieverycorp.jpg',
    category: 'Electro Dub',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://thieverycorporation.com/',
    youtube: 'https://www.youtube.com/channel/UCzFpkcvn4ulINJsS5SpLaJQ',
    facebook: 'https://www.facebook.com/thieverycorporation',
    instagram: 'https://www.instagram.com/thieverycorporation/',
    twitter: 'https://twitter.com/ThieveryCorpDC',
    spotify: 'https://open.spotify.com/artist/25KNo5GDS6ZpLkjasaecA3',
    deezer: 'https://www.deezer.com/mx/artist/366',
    apple: 'https://music.apple.com/mx/artist/armin-van-buuren/17976294',
    soundcloud: 'https://soundcloud.com/thieverycorporation',
    tidal: 'https://listen.tidal.com/artist/3518232',
    wiki: 'https://es.wikipedia.org/wiki/Thievery_Corporation',
    ticketmaster:
      'https://www.ticketmaster.com/thievery-corporation-tickets/artist/807166',
    tiktok: 'https://www.tiktok.com/@thieverycorporationdc?lang=es',
    napster: 'https://mx.napster.com/artist/thievery-corporation',
  },
  {
    title: 'Bonobo',
    description:
      'Simon Green, conocido por su nombre artístico Bonobo, es un músico, productor y DJ británico radicado en Los Ángeles. Debutó con una estética trip hop y, desde entonces, ha explorado enfoques más animados, así como influencias del jazz y la música del mundo.',
    videourl:
      'https://youtube.com/playlist?list=PLXEYOK9pTABDJgg_q6ikyRM5q4go-N4wm',
    price: 80,
    coverImg: 'bonobo.jpg',
    category: 'Electro Dub',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://bonobomusic.com/',
    youtube: 'https://www.youtube.com/channel/UCzFpkcvn4ulINJsS5SpLaJQ',
    facebook: 'https://www.facebook.com/bonoboofficial',
    instagram: 'https://www.instagram.com/si_bonobo/',
    twitter: 'https://twitter.com/ThieveryCorpDC',
    spotify: 'https://open.spotify.com/artist/0cmWgDlu9CwTgxPhf403hb',
    deezer: 'https://www.deezer.com/mx/artist/2108',
    apple: 'https://music.apple.com/us/artist/bonobo/416281071',
    soundcloud: 'https://soundcloud.com/bonobo',
    tidal: 'https://listen.tidal.com/artist/3518222',
    wiki: 'https://en.wikipedia.org/wiki/Bonobo_(musician)',
    ticketmaster: 'https://www.ticketmaster.com/bonobo-tickets/artist/800813',
    tiktok: 'https://www.tiktok.com/@bonoboofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJZ2AS/bonobo',
  },
  {
    title: 'The ORB',
    description:
      "The Orb es un grupo de música electrónica inglés fundado en 1988 por Alex Paterson y Jimmy Cauty. Conocidos por su sonido psicodélico, The Orb desarrolló un culto de seguidores entre los asistentes a clubes que se recuperaban de subidones inducidos por drogas. Su influyente álbum debut de 1991, The Orb's Adventures Beyond the Ultraworld, fue pionero en el incipiente movimiento ambient house del Reino Unido, mientras que su sucesor, U.F.Orb, que alcanzó el número uno en las listas británicas, representó el apogeo comercial del género.",
    videourl:
      'https://youtube.com/playlist?list=PLYBrBkAlaX-VZM_mjalsjWLlszRFEGTCW',
    price: 80,
    coverImg: 'orb.jpg',
    category: 'Electro Dub',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.theorb.com/',
    youtube: 'https://www.youtube.com/@theorbofficial1297',
    facebook: 'https://www.facebook.com/TheOrb',
    instagram: 'https://www.instagram.com/theorblive/',
    twitter: 'https://twitter.com/orbinfo',
    spotify: 'https://open.spotify.com/artist/5HAtRoEPUvGSA7ziTGB1cF',
    deezer: 'https://www.deezer.com/mx/album/415660217',
    apple: 'https://music.apple.com/us/artist/the-orb/77255',
    soundcloud: 'https://soundcloud.com/the-orb-official',
    tidal: 'https://listen.tidal.com/artist/19435',
    wiki: 'https://en.wikipedia.org/wiki/The_Orb',
    ticketmaster: 'https://www.ticketmaster.com/the-orb-tickets/artist/806748',
    tiktok: 'https://www.tiktok.com/@orbmusic00?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKALQC/the-orb',
  },

  {
    title: 'Röyskopp',
    description:
      'Originalmente, Berge y Brundtland fueron compañeros de colegio en Tromsø, Noruega. Los dos experimentaron con varios estilos de música electrónica en la escena techno de Tromsø antes de seguir por caminos distintos. Unos años más tarde, los dos se volvieron a juntar y fundaron Röyksopp durante la Bergensbølgen (término utilizado por la prensa para mencionar a las bandas emergentes en Bergen entre 1990 y comienzos del 2000). La publicación de su álbum debut en 2001, Melody A.M., les consolidó en la escena de la música electrónica.',
    videourl:
      'https://youtube.com/playlist?list=PLXz0F2Zo_KsNDlw4qUq4IgCynlqSSajv3',
    price: 80,
    coverImg: 'royskopp.jpg',
    category: 'Electro Dub',
    location: 'Noruega',
    locationImg: 'norway.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://profoundmysteries.royksopp.com/',
    youtube: 'https://www.youtube.com/channel/UC9lmMDVtBa6Hppy8Ehfpwpg',
    facebook: 'https://www.facebook.com/Royksopp',
    instagram: 'https://www.instagram.com/royksopp/',
    twitter: 'https://twitter.com/royksopp',
    spotify:
      'https://open.spotify.com/artist/5nPOO9iTcrs9k6yFffPxjH?si=NysHhi1RQgG7C_U3QmQnBA&dl_branch=1&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/480',
    apple: 'https://music.apple.com/us/artist/r%C3%B6yksopp/3432068',
    soundcloud: 'https://soundcloud.com/martins-grass/sets/roeyskopp',
    tidal: 'https://listen.tidal.com/artist/9246',
    wiki: 'https://en.wikipedia.org/wiki/R%C3%B6yksopp',
    ticketmaster:
      'https://www.ticketmaster.com/royksopp-tickets/artist/2940768',
    tiktok: 'https://www.tiktok.com/@royksopp.official?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000S9XZMS/r%C3%B6yksopp',
  },
  {
    title: 'The KLF',
    description:
      'The KLF (también conocidos como los Justified Ancients of Mu Mu, los JAMs, los Timelords y otros nombres) son una banda electrónica británica formada en Londres en 1987. Bill Drummond (alias King Boy D) y Jimmy Cauty (alias Rockman Rock) comenzaron lanzando discos inspirados en el hip hop y cargados de samples bajo el nombre de los JAMs. Como los Timelords, grabaron el sencillo número uno en Gran Bretaña Doctorin the Tardis y documentaron el proceso de hacer un disco exitoso en el libro The Manual (How to Have a Number One the Easy Way). Como The KLF, Drummond y Cauty fueron pioneros del stadium house (música rave con una producción pop-rock y ruido de multitudes sampleado) y, con su LP de 1990 Chill Out, del género ambient house. The KLF lanzaron una serie de éxitos internacionales bajo su propio sello discográfico, KLF Communications, y se convirtieron en el acto de sencillos más vendido del mundo en 1991.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlWXgCc3uyinqNHrNoQhuw30',
    price: 80,
    coverImg: 'klf.jpg',
    category: 'Electro Dub',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://klf.de/home/',
    youtube: 'https://www.youtube.com/channel/UCbsEHtpoQxyWVibIPerXhug',
    facebook: 'https://www.facebook.com/profile.php?id=100058233363200',
    instagram: 'https://www.instagram.com/the_klf_fan_page/',
    twitter: 'https://twitter.com/KLF_Online',
    spotify:
      'https://open.spotify.com/artist/6dYrdRlNZSKaVxYg5IrvCH?si=PFB9z9j8RcOFou_GRnvw1g&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/2846',
    apple: 'https://music.apple.com/us/artist/the-klf/2206365',
    soundcloud: 'https://soundcloud.com/theklfofficial',
    tidal: 'https://listen.tidal.com/artist/4210003',
    wiki: 'https://en.wikipedia.org/wiki/The_KLF',
    ticketmaster: 'https://www.ticketmaster.com/klf-tickets/artist/1956',
    tiktok: 'https://www.tiktok.com/@theklf?lang=es',
    napster: 'https://music.amazon.co.uk/artists/B00GPH8O46/the-klf',
  },
  {
    title: 'Late Night Alumni',
    description:'Late Night Alumni es un grupo estadounidense de house compuesto por Becky Jean Williams, John Hancock, Finn Bjarnson (Finnstagram) y Ryan Raddon (Kaskade). Son principalmente conocidos por mezclar música dance con cuerdas y voces suaves de estilo trance.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_k7CpXF8Q9tks2rmQ4sOC_CGhyDCsLZu14',
    price: 80,
    coverImg: 'lna.jpg',
    category: 'Electro Dub',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.latenightalumni.com/',
    youtube: 'https://www.youtube.com/@LateNightAlumni',
    facebook: 'https://www.facebook.com/lnaofficial',
    instagram: 'https://www.instagram.com/latenightalumni/',
    twitter: 'https://twitter.com/latenightalumni',
    spotify: 'https://open.spotify.com/artist/6JtFllJR7nhh8fa6oGefSj',
    deezer: 'https://www.deezer.com/mx/artist/3728',
    apple: 'https://music.apple.com/us/artist/late-night-alumni/2900820',
    soundcloud: 'https://soundcloud.com/latenightalumni',
    tidal: 'https://listen.tidal.com/artist/3645686',
    wiki: 'https://en.wikipedia.org/wiki/Late_Night_Alumni',
    ticketmaster:
      'https://www.ticketmaster.com/late-night-alumni-tickets/artist/1812006',
    tiktok: 'https://www.tiktok.com/@latenightalumni?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000S2HIQY/late-night-alumni',
  },
  {
    "title": "Air",
    "description": "Air es un dúo musical francés de Versalles, compuesto por Nicolas Godin y Jean-Benoît Dunckel. Su aclamado álbum debut, Moon Safari, que incluye la canción Sexy Boy, fue un éxito internacional en 1998. Su siguiente trabajo, The Virgin Suicides, fue la banda sonora de la primera película del mismo nombre dirigida por Sofia Coppola. Desde entonces, la banda ha lanzado los álbumes 10 000 Hz Legend, Talkie Walkie, Pocket Symphony, Love, Le voyage dans la lune y Music for Museum. El grupo se inspira en una amplia variedad de estilos musicales y artistas.",
    "videourl": "https://youtube.com/playlist?list=PLSf4nhQYhfbB6H0PRRcnLao3QI_7Kvy7V",
    "price": 80,
    "coverImg": "air.jpg",
    "category": "Electro Dub",
    "location": "France",
    "locationImg": "france.jpg",
    "openSpots": null,
    "likes": 1000,
    "websiteurl": "http://www.airfrenchband.com/",
    "youtube": "https://www.youtube.com/channel/UCjXmNnJm9jwtGg094LRoFOw",
    "facebook": "https://www.facebook.com/intairnet",
    "instagram": "https://www.instagram.com/airfrenchband/",
    "twitter": "https://twitter.com/airofficial",
    "spotify": "https://open.spotify.com/artist/1P6U1dCeHxPui5pIrGmndZ",
    "deezer": "https://www.deezer.com/mx/artist/369",
    "apple": "https://music.apple.com/us/artist/air/5641488",
    "soundcloud": "https://soundcloud.com/officialair",
    "tidal": "https://listen.tidal.com/artist/9101",
    "wiki": "https://en.wikipedia.org/wiki/Air_(French_band)",
    "ticketmaster": "https://www.ticketmaster.com/air-tickets/artist/1396753",
    "tiktok": "https://www.tiktok.com/@air.band?lang=es",
    "napster": "https://music.amazon.com.mx/artists/B000SA14KM/air"
  },
  {
    "title": "MGMT",
    "description": "MGMT (/ɛm-dʒi-ɛm-tiː/) es una banda de rock estadounidense formada en 2002 en Middletown, Connecticut. Fue fundada por los cantantes y multiinstrumentistas Andrew VanWyngarden y Ben Goldwasser.Originalmente firmados con Cantora Records por el cofundador del emergente sello, Will Griggs, estudiante de NYU, MGMT posteriormente firmó con Columbia y RED Ink en 2006 y lanzó su álbum debut Oracular Spectacular al año siguiente. Después del lanzamiento de Oracular Spectacular, Richardson, Berman y Matthew Asti se unieron a la banda principal en el estudio para Congratulations, que fue lanzado el 13 de abril de 2010. En enero de 2011, comenzaron a trabajar en su tercer álbum de estudio homónimo. Este fue lanzado el 17 de septiembre de 2013, y fue lanzado como una exclusiva temprana en Rdio el 9 de septiembre de 2013. El cuarto álbum de estudio de la banda, titulado Little Dark Age, fue lanzado en febrero de 2018 y marcó el final de su contrato con Columbia. A partir de 2019, el dúo comenzó a producir música de manera independiente, por primera vez desde 2006. A finales de 2019, lanzaron una nueva canción llamada In the Afternoon como su primer sencillo completamente autoproducido.",
    "videourl": "https://youtube.com/playlist?list=PL1vTPUOoi_HTdeGjj1kYjerLegF3K8LCd&si=xrMyPGNvoPCGToNo",
    "price": 80,
    "coverImg": "mgmt.jpg",
    "category": "Electro Dub",
    "location": "France",
    "locationImg": "france.jpg",
    "openSpots": null,
    "likes": 1000,
    "websiteurl": "https://whoismgmt.com/",
    "youtube": "https://www.youtube.com/channel/UCC2i4uIWWjx5L5V5Q5k7eiQ",
    "facebook": "https://www.facebook.com/mgmt/",
    "instagram": "https://www.instagram.com/whoismgmt/",
    "twitter": "https://x.com/whoisMGMT",
    "spotify": "https://open.spotify.com/artist/0SwO7SWeDHJijQ3XNS7xEE",
    "deezer": "https://www.deezer.com/mx/artist/69627",
    "apple": "https://music.apple.com/us/artist/mgmt/251553551",
    "soundcloud": "https://soundcloud.com/whoismgmt",
    "tidal": "https://listen.tidal.com/artist/3504370",
    "wiki": "https://en.wikipedia.org/wiki/MGMT",
    "ticketmaster": "https://www.ticketmaster.com/mgmt-tickets/artist/1205088",
    "tiktok": "https://www.tiktok.com/@whoismgmt",
    "napster": "https://music.amazon.com.mx/artists/B000XUBJ4S/mgmt"
  },
  {
    "title": "Empire of The Sun",
    "description": "Empire of the Sun es un dúo australiano de música electrónica formado en 2007. El dúo es una colaboración entre Luke Steele, anteriormente de la banda de rock alternativo The Sleepy Jackson, y Nick Littlemore, de la banda de música electrónica PNAU.El álbum debut de Empire of the Sun en 2008, Walking on a Dream, le trajo al dúo éxito internacional y ha sido certificado doble platino en Australia y oro en el Reino Unido. El álbum proporcionó varios sencillos que llegaron a las listas internacionales, incluyendo el tema principal, que alcanzó el número diez en la ARIA Singles Chart australiana, y We Are the People, que alcanzó el número catorce en la UK Singles Chart. El segundo álbum de la banda, Ice on the Dune, fue lanzado en junio de 2013, mientras que su tercer álbum, Two Vines, fue lanzado en octubre de 2016. Un cuarto álbum, Ask That God, fue lanzado en julio de 2024.",
    "videourl": "https://youtube.com/playlist?list=PL7-vLfOD02lTbzX5Q9j3V6MsjQ8b50W4c&si=HR3DBbe5Z6HlO9hL",
    "price": 80,
    "coverImg": "empireofthesun.jpg",
    "category": "Electro Dub",
    "location": "Austarlia",
    "locationImg": "australia.jpg",
    "openSpots": 1000,
    "likes": 1000,
    "websiteurl": "https://empireofthesun.co/",
    "youtube": "https://www.youtube.com/channel/UCyacMWc-JhzX6iq2PCF-4jw",
    "facebook": "https://www.facebook.com/empireofthesun?mibextid=uzlsIk",
    "instagram": "https://www.instagram.com/empireofthesunsound?igsh=a3N1NXJpbzkzdjQ3",
    "twitter": "https://x.com/empireofthesun?s=21&t=QfkoZv7VttIZngjRBBWSJQ",
    "spotify": "https://open.spotify.com/artist/67hb7towEyKvt5Z8Bx306c",
    "deezer": "https://www.deezer.com/mx/artist/185473",
    "apple": "https://music.apple.com/us/artist/empire-of-the-sun/284433289",
    "soundcloud": "https://soundcloud.com/empireofthesunsound",
    "tidal": "https://listen.tidal.com/artist/3542951",
    "wiki": "https://en.wikipedia.org/wiki/Empire_of_the_Sun_(band)",
    "ticketmaster": "https://www.ticketmaster.com/empire-of-the-sun-tickets/artist/1439524",
    "tiktok": "https://www.tiktok.com/@empireofthesun?_t=8kxI4KrA2JC&_r=1",
    "napster": "https://music.amazon.com/artists/B001Q1RXGQ/empire-of-the-sun"
  },
  {
    title: 'Moby',
    description:
      "Richard Melville Hall (Harlem, Nueva York, Estados Unidos; 11 de septiembre de 1965), más conocido como Moby, es un productor y compositor musical estadounidense.​ Tomó su nombre artístico del libro más famoso de su conocido tío tatarabuelo, Herman Melville, Moby-Dick. Después de colocar ocho de sus sencillos en la lista de sencillos del Reino Unido​ en los años 1990 haciendo música techno, su mayor éxito fue Play, lanzado en 1999, del cual vendió doce millones de copias en todo el mundo. Ha tenido conflictos dialécticos con Marilyn Manson y Eminem. Desde 2007 sube música a su cuenta de YouTube y cuenta con más de cuatrocientos noventa millones de reproducciones.3​ Su etapa como solista, cuenta con doce álbumes de estudio, diez álbumes recopilatorios, más de sesenta y cinco sencillos, entre otras apariciones. A lo largo de su carrera vendió más de veinte millones de álbumes y tres millones solo en Estados Unidos, tiene certificaciones tales como un disco de diamante en Francia, veinte discos de platino, dieciséis discos de oro y uno de plata.",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lil43yNsZ_N1HvGl9zeAv8QRt2_TFJsVA&si=ebcMwZih909PRJwJ',
    price: 80,
    coverImg: 'moby.jpg',
    category: 'Electronica',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://moby.com/',
    youtube: 'https://www.youtube.com/@moby',
    facebook: 'https://www.facebook.com/mobymusic',
    instagram: 'https://www.instagram.com/moby',
    twitter: 'https://x.com/thelittleidiot',
    spotify: 'https://open.spotify.com/artist/3OsRAKCvk37zwYcnzRf5XF',
    deezer: 'https://www.deezer.com/mx/artist/493',
    apple: 'https://music.apple.com/mx/artist/moby/789023',
    soundcloud: 'https://soundcloud.com/moby',
    tidal: 'https://listen.tidal.com/artist/9045',
    wiki: 'https://es.wikipedia.org/wiki/Moby',
    ticketmaster:
      'https://www.ticketmaster.com/moby-tickets/artist/806872',
    tiktok: 'https://www.tiktok.com/@moby',
    napster: 'https://music.amazon.com.mx/artists/B000QJPGP4/moby',
  },
 
  {
    title: 'Glass Beams',
    description:
      "Glass Beams es un trío musical australiano de Melbourne, Australia. La banda fue fundada por Rajan Silva en 2020 durante la pandemia de COVID-19, junto con otros dos miembros no identificados. Su EP debut, Mirage, fue lanzado en junio de 2021. La banda combina sintetizadores, rock, psicodelia, guitarra eléctrica, instrumentales orientales y sutiles voces susurrantes. En todas sus apariciones públicas, han usado máscaras de vidrio adornadas con joyas doradas.",
    videourl:
      'https://youtube.com/playlist?list=UULFz2BbywXgCxZcpAiXPlKT4Q&si=4M8gEi_q7otoNRFL',
    price: 80,
    coverImg: 'glassbeams.jpg',
    category: 'Electro Dub',
    location: 'Australia',
    locationImg: 'australia.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://glassbeams.com/',
    youtube: 'https://www.youtube.com/@GlassBeams',
    facebook: 'https://www.facebook.com/GlassBeams/',
    instagram: 'https://www.instagram.com/glass_beams',
    twitter: 'https://x.com/glass_beams',
    spotify: 'https://open.spotify.com/artist/1LTFJvVvRw7ghAyThxYmnF',
    deezer: 'https://www.deezer.com/mx/artist/126351422',
    apple: 'https://music.apple.com/us/artist/glass-beams/1558093934',
    soundcloud: 'https://soundcloud.com/glassbeams',
    tidal: 'https://listen.tidal.com/artist/23927301',
    wiki: 'https://en.wikipedia.org/wiki/Glass_Beams',
    ticketmaster: 'https://www.ticketmaster.com/glass-beams-tickets/artist/3116805',
    tiktok: 'https://www.tiktok.com/@glass_beams',
    napster: 'https://music.amazon.com.mx/artists/B08YQGTM8M/glass-beams',
  },
]

export default CardData14
